import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import * as opportunityReducer from '../reducers/opportunity.reducer';
import { PlatformFee } from '../../models/user-fees.model';
import { Yield } from '../../models/yield-opportunity.model';

export const OPPORTUNITY_FEATURE_NAME = `opportunity`;

export interface State {
  opportunity: opportunityReducer.State;
}

export const selectOpportunityState: MemoizedSelector<State, opportunityReducer.State> =
  createFeatureSelector<opportunityReducer.State>(OPPORTUNITY_FEATURE_NAME);

export const selectPortfolioValue: MemoizedSelector<State, number> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.portfolioValue
);

export const selectMissedPassiveYield: MemoizedSelector<State, number> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.missedPassiveYield
);

export const selectBestAnnualFees: MemoizedSelector<State, number> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.bestAnnualFees
);

export const selectAvailableYields: MemoizedSelector<State, Yield[]> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.availableYields
);

export const selectCheapestPlatforms: MemoizedSelector<State, PlatformFee[]> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.cheapestPlatforms
);

export const selectMissingOpportunity: MemoizedSelector<State, number> = createSelector(
  selectOpportunityState,
  (state: opportunityReducer.State) => state.missingOpportunity
);
