import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromPayment from '../../../store/selectors/payment.selector';
import { loadPaymentsAction } from '../../../store/actions/payment.action';
import { takeUntil, map } from 'rxjs/operators';
import { Payment } from '../../../models/payment.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: `app-checkout-success-dialog`,
  standalone: true,
  imports: [TranslateModule],
  templateUrl: `./checkout-success-dialog.component.html`,
  styleUrls: [`./checkout-success-dialog.component.scss`],
})
export class CheckoutSuccessDialogComponent implements OnInit, OnDestroy {
  paymentId: string;
  payment: Payment;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CheckoutSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { paymentId: string },
    private readonly paymentStore$: Store<fromPayment.State>
  ) {}

  ngOnInit(): void {
    localStorage.removeItem(`payment_callback_url`);
    this.paymentId = this.data.paymentId;
    this.paymentStore$.dispatch(loadPaymentsAction());

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPayments),
        map((payments: Payment[]) => {
          if (payments) {
            const payment = payments.find((p: Payment) => p.id === this.paymentId);

            if (payment) {
              setTimeout(() => {
                this.payment = payment;

                setTimeout(() => {
                  this.dialogRef.close();
                }, 3000);
              }, 5000);
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
