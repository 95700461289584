/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import {
  AvailableAddons,
  AvailablePlans,
  CheckoutType,
  FiscalYearPlanEstimate,
  Payment,
  PaymentEstimateV3,
  PaymentMethod,
  PendingCharge,
} from '../../models/payment.model';
import { UserAddon, UserPlan } from '../../models/user.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { AddOnDetails } from '../../models/addon.model';
import { InvoiceDetails } from '../../models/invoice.model';
import { SubscriptionNextPayment } from '../../models/subscription.model';

// eslint-disable-next-line no-shadow
export enum PaymentActions {
  // Plan
  LoadAvailablePlans = `[Payment] Load Available Plans`,
  SetAvailablePlans = `[Payment] Set Available Plans`,
  UpgradePlan = `[Payment] Upgrade Plan`,

  // Addon
  LoadAvailableAddons = `[Payment] Load Available Addons`,
  SetAvailableAddons = `[Payment] Set Available Addons`,
  LoadAddonsDetails = `[Payment] Load Addons Details`,
  SetAddonsDetails = `[Payment] Set Addons Details`,
  EnableAddon = `[Payment] Enable Addon`,

  // Charge
  LoadPendingCharge = `[Payment] Load Pending Charge`,
  SetPendingCharge = `[Payment] Set Pending Charge`,
  PayCharge = `[Payment] Pay Charge`,

  // Subscription
  RenewSubscription = `[Payment] Renew Subscription`,
  LoadSubscriptionNextPayment = `[Payment] Load Subscription Next Payment`,
  SetSubscriptionNextPayment = `[Payment] Set Subscription Next Payment`,

  // Payment
  LoadPayments = `[Payment] Load Payments`,
  SetPayments = `[Payment] Set Payments`,
  LoadPaymentEstimate = `[Payment] Load Payment Estimate`,
  SetPaymentEstimate = `[Payment] Set Payment Estimate`,
  LoadPlansByFiscalYears = `[Payment] Load Plans By Fiscal Years`,
  SetPlansByFiscalYears = `[Payment] Set Plans By Fiscal Years`,

  // Invoice
  DownloadInvoice = `[Payment] Download Invoice`,
  GenerateInvoice = `[Payment] Generate Invoice`,

  // Vault
  ApplyVault = `[Payment] Apply Vault`,

  // Voucher
  CheckVoucherCode = `[Payment] Check Voucher Code`,
  SetVoucherResponse = `[Payment] Set Voucher Response`,

  // Upgrade Fishing
  LoadUpgradeFishingEstimate = `[Payment] Load Upgrade Fishing Estimate`,
  SetUpgradeFishingEstimate = `[Payment] Set Upgrade Fishing Estimate`,
}

export const loadPaymentsAction = createAction(PaymentActions.LoadPayments);

export const setPaymentsAction = createAction(PaymentActions.SetPayments, props<{ payments: Payment[] }>());

export const loadAvailablePlansAction = createAction(PaymentActions.LoadAvailablePlans);

export const setAvailablePlansAction = createAction(
  PaymentActions.SetAvailablePlans,
  props<{ availablePlans: AvailablePlans }>()
);

export const applyVaultAction = createAction(PaymentActions.ApplyVault);

export const checkVoucherCodeAction = createAction(
  PaymentActions.CheckVoucherCode,
  props<{
    voucherCode: string;
    fiscalYear: number;
    checkoutType: CheckoutType;
    requestedPlan?: UserPlan;
    requestedAddOn?: UserAddon;
    useVault: boolean;
  }>()
);

export const upgradePlanAction = createAction(
  PaymentActions.UpgradePlan,
  props<{
    paymentMethod: PaymentMethod;
    requestedPlan: UserPlan;
    fiscalYear: number;
    papCookie?: string;
    tags?: string;
    useVault?: boolean;
    code?: string;
  }>()
);

export const downloadInvoiceAction = createAction(PaymentActions.DownloadInvoice, props<{ paymentId: string }>());

export const generateInvoiceAction = createAction(
  PaymentActions.GenerateInvoice,
  props<{ paymentId: string; invoiceDetails: InvoiceDetails }>()
);

export const renewSubscriptionAction = createAction(
  PaymentActions.RenewSubscription,
  props<{ requestedPlan: UserPlan }>()
);

export const setVoucherResponseAction = createAction(
  PaymentActions.SetVoucherResponse,
  props<{ voucherResponse: GenericResponse }>()
);

export const loadAvailableAddonsAction = createAction(PaymentActions.LoadAvailableAddons);

export const setAvailableAddonsAction = createAction(
  PaymentActions.SetAvailableAddons,
  props<{ availableAddons: AvailableAddons }>()
);

export const loadAddonsDetailsAction = createAction(PaymentActions.LoadAddonsDetails);

export const setAddonsDetailsAction = createAction(
  PaymentActions.SetAddonsDetails,
  props<{ addonsDetails: AddOnDetails[] }>()
);

export const enableAddonAction = createAction(
  PaymentActions.EnableAddon,
  props<{
    paymentMethod: PaymentMethod;
    requestedAddOn: UserAddon;
    useVault: boolean;
    code: string;
    fiscalYear: number;
  }>()
);

export const loadPendingChargeAction = createAction(PaymentActions.LoadPendingCharge);

export const setPendingChargeAction = createAction(
  PaymentActions.SetPendingCharge,
  props<{ pendingCharge: PendingCharge }>()
);

export const payChargeAction = createAction(PaymentActions.PayCharge, props<{ paymentMethod: PaymentMethod }>());

export const loadPaymentEstimateAction = createAction(
  PaymentActions.LoadPaymentEstimate,
  props<{
    fiscalYear: number;
    checkoutType: CheckoutType;
    requestedPlan?: UserPlan;
    requestedAddOn?: UserAddon;
    useVault?: boolean;
    code?: string;
  }>()
);

export const setPaymentEstimateAction = createAction(
  PaymentActions.SetPaymentEstimate,
  props<{ paymentEstimate: PaymentEstimateV3 }>()
);

export const loadUpgradeFishingEstimateAction = createAction(
  PaymentActions.LoadUpgradeFishingEstimate,
  props<{
    fiscalYear: number;
    checkoutType: CheckoutType;
    requestedPlan?: UserPlan;
    requestedAddOn?: UserAddon;
    computeAssessmentStatus?: boolean;
  }>()
);

export const setUpgradeFishingEstimateAction = createAction(
  PaymentActions.SetUpgradeFishingEstimate,
  props<{ upgradeFishingEstimate: PaymentEstimateV3; computeAssessmentStatus?: boolean }>()
);

export const loadPlansByFiscalYearsAction = createAction(PaymentActions.LoadPlansByFiscalYears);

export const setPlansByFiscalYearsAction = createAction(
  PaymentActions.SetPlansByFiscalYears,
  props<{ plansByFiscalYears: Map<number, FiscalYearPlanEstimate> }>()
);

export const loadSubscriptionNextPaymentAction = createAction(PaymentActions.LoadSubscriptionNextPayment);

export const setSubscriptionNextPaymentAction = createAction(
  PaymentActions.SetSubscriptionNextPayment,
  props<{ subscriptionNextPayment: SubscriptionNextPayment }>()
);
