/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { PlatformFee } from '../../models/user-fees.model';
import { Yield } from '../../models/yield-opportunity.model';

// eslint-disable-next-line no-shadow
export enum OpportunityActions {
  SetPortfolioValue = `[Opportunity] Set Portfolio Value`,
  SetMissedPassiveYield = `[Opportunity] Set Missed Passive Yield`,
  SetCheapestPlatforms = `[Opportunity] Set Cheapest Platforms`,
  SetBestAnnualFees = `[Opportunity] Set Best Annual Fees`,
  SetMissingOpportunity = `[Opportunity] Set Missing Opportunity`,
  SetAvailableYields = `[Opportunity] Set Available Yields`,
}

export const setPortfolioValueAction = createAction(
  OpportunityActions.SetPortfolioValue,
  props<{ portfolioValue: number }>()
);

export const setMissedPassiveYieldAction = createAction(
  OpportunityActions.SetMissedPassiveYield,
  props<{ missedPassiveYield: number }>()
);

export const setCheapestPlatformsAction = createAction(
  OpportunityActions.SetCheapestPlatforms,
  props<{ cheapestPlatforms: PlatformFee[] }>()
);

export const setBestAnnualFeesAction = createAction(
  OpportunityActions.SetBestAnnualFees,
  props<{ bestAnnualFees: number }>()
);

export const setMissingOpportunityAction = createAction(
  OpportunityActions.SetMissingOpportunity,
  props<{ missingOpportunity: number }>()
);

export const setAvailableYieldsAction = createAction(
  OpportunityActions.SetAvailableYields,
  props<{ availableYields: Yield[] }>()
);
