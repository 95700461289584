import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { ReportedToken } from '../../../shared/models/reported-token.model';
import { Scam } from '../../../shared/models/scam.model';
import { loadUserReportedTokenListAction, loadUserScamListAction } from '../../../shared/store/actions/shared.action';
import * as fromShared from '../../../shared/store/selectors/shared.selector';
import { OpportunitiesHeaderComponent } from '../../components/opportunities-header/opportunities-header.component';
import {
  LiveBalanceResponse,
  LiveBalanceResponseAccount,
  LiveBalanceResponseItem,
  YieldPosition,
} from '../../models/live-balance.model';
import { MarketFeed } from '../../models/market.model';
import { PlatformFee, UserFees } from '../../models/user-fees.model';
import { Yield, YieldOpportunity } from '../../models/yield-opportunity.model';
import { MarketService } from '../../services/market.service';
import {
  loadLiveBalancesAction,
  loadMarketHistoryAction,
  loadUserFeesAction,
  loadUserFeesByPlatformAction,
  loadYieldOpportunitiesAction,
  startFeedStreamAction,
  stopFeedStreamAction,
} from '../../store/actions/insight.action';
import {
  setAvailableYieldsAction,
  setBestAnnualFeesAction,
  setCheapestPlatformsAction,
  setMissedPassiveYieldAction,
  setMissingOpportunityAction,
  setPortfolioValueAction,
} from '../../store/actions/opportunity.action';
import * as fromInsight from '../../store/selectors/insight.selector';
import * as fromOpportunity from '../../store/selectors/opportunity.selector';

@Component({
  selector: `app-opportunities-page`,
  standalone: true,
  imports: [CommonModule, RouterModule, OpportunitiesHeaderComponent],
  templateUrl: `./opportunities-page.component.html`,
})
export class OpportunitiesPageComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  hideScams = true;

  marketFeed: MarketFeed;
  liveBalances: LiveBalanceResponse;
  yieldOpportunities: YieldOpportunity[];
  userFees: UserFees;
  userScamList: Scam[];
  userReportedTokenList: ReportedToken[];

  yieldableTokens: Map<string, number> = new Map<string, number>([]);
  availableYields: Yield[];
  cheapestPlatforms: PlatformFee[];

  missedPassiveYield = 0;
  bestAnnualFees = 0;

  liveBalancesRetryInterval: any;

  constructor(
    private readonly insightStore$: Store<fromInsight.State>,
    private readonly opportunityStore$: Store<fromOpportunity.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly marketService: MarketService
  ) {}

  ngOnInit(): void {
    this.insightStore$.dispatch(startFeedStreamAction());
    this.insightStore$.dispatch(loadMarketHistoryAction());
    this.insightStore$.dispatch(loadLiveBalancesAction());
    this.insightStore$.dispatch(loadYieldOpportunitiesAction());
    this.insightStore$.dispatch(loadUserFeesAction());
    this.insightStore$.dispatch(loadUserFeesByPlatformAction());
    this.sharedStore$.dispatch(loadUserScamListAction());
    this.sharedStore$.dispatch(loadUserReportedTokenListAction());

    combineLatest([
      this.insightStore$.select(fromInsight.selectMarketFeed),
      this.insightStore$.select(fromInsight.selectLiveBalances),
      this.insightStore$.select(fromInsight.selectYieldOpportunities),
      this.insightStore$.select(fromInsight.selectUserFees),
      this.sharedStore$.select(fromShared.selectUserScamList),
      this.sharedStore$.select(fromShared.selectUserReportedTokenList),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([marketFeed, liveBalances, yieldOpportunities, userFees, userScamList, userReportedTokenList]) => {
          this.marketFeed = marketFeed;
          this.liveBalances = liveBalances;
          this.yieldOpportunities = yieldOpportunities;
          this.userFees = userFees;
          this.userScamList = userScamList;
          this.userReportedTokenList = userReportedTokenList;

          if (
            this.marketFeed &&
            this.liveBalances &&
            this.yieldOpportunities &&
            this.userFees &&
            this.userScamList &&
            this.userReportedTokenList
          ) {
            this.refreshLiveBalances();
            this.computeYieldableTokens();
            this.computeAvailableYields();

            this.computePortfolioValue();
            this.computeMissedPassiveYield();
            this.computeCheapestPlatforms();
            this.computeBestAnnualFees();

            this.computeMissingOpportunity();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.insightStore$.dispatch(stopFeedStreamAction());
    clearInterval(this.liveBalancesRetryInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }

  refreshLiveBalances(): void {
    // Create refresh balances interval
    clearInterval(this.liveBalancesRetryInterval);

    if (this.liveBalances.retryIn > 0) {
      this.liveBalancesRetryInterval = setInterval(() => {
        this.insightStore$.dispatch(loadLiveBalancesAction());
      }, this.liveBalances.retryIn);
    }
  }

  computeYieldableTokens(): void {
    const yieldableTokens: Map<string, number> = new Map<string, number>([]);

    this.liveBalances.items
      .filter(
        (balance: LiveBalanceResponseItem) =>
          !this.marketService.isScam(balance, this.userScamList, this.userReportedTokenList)
      )
      .forEach((balance: LiveBalanceResponseItem) => {
        const account: LiveBalanceResponseAccount = this.liveBalances.accounts.find(
          (acc: LiveBalanceResponseAccount) => acc.accountId === balance.accountId
        );

        const yieldPositions: YieldPosition[] = account.yieldPositions?.filter(
          (position: YieldPosition) => position.token === balance.token
        );

        let quantity = balance.quantity;
        if (yieldPositions?.length > 0) {
          yieldPositions.forEach((position: YieldPosition) => {
            quantity -= position.quantity;
          });
        }

        if (quantity > 0) {
          const yieldableTokenQuantity = yieldableTokens.get(balance.token);
          if (yieldableTokenQuantity) {
            yieldableTokens.set(balance.token, yieldableTokenQuantity + quantity);
          } else {
            yieldableTokens.set(balance.token, quantity);
          }
        }
      });

    this.yieldableTokens = yieldableTokens;
  }

  computePortfolioValue(): void {
    const portfolioValue = this.liveBalances.items
      .filter(
        (balance: LiveBalanceResponseItem) =>
          !this.marketService.isScam(balance, this.userScamList, this.userReportedTokenList)
      )
      .map((balance: LiveBalanceResponseItem) => {
        const currentPrice = this.marketService.getTokenPrice(this.marketFeed.marketFeed, balance.token);
        const amount = currentPrice * balance.quantity;
        return amount;
      })
      .reduce((acc: number, amount: number) => {
        return acc + amount;
      }, 0);

    this.opportunityStore$.dispatch(setPortfolioValueAction({ portfolioValue }));
  }

  computeMissedPassiveYield(): void {
    const missedPassiveYield = this.availableYields
      .map((availableYield: Yield) => availableYield.yieldAmount)
      .reduce((acc: number, amount: number) => acc + amount, 0);

    this.missedPassiveYield = missedPassiveYield;

    this.opportunityStore$.dispatch(setMissedPassiveYieldAction({ missedPassiveYield }));
  }

  computeAvailableYields(): void {
    const availableYields: Yield[] = [];

    this.yieldableTokens.forEach((quantity: number, token: string) => {
      const yieldOpportunity = this.marketService.getBestYieldOpportunity(this.yieldOpportunities, token);

      if (yieldOpportunity) {
        const price = this.marketService.getTokenPrice(this.marketFeed.marketFeed, token);
        const amount = quantity * price;
        const yieldQuantity = this.marketService.computeYieldQuantity(quantity, yieldOpportunity.apy);
        const yieldAmount = yieldQuantity * price;

        availableYields.push({
          token,
          platform: yieldOpportunity.platform,
          quantity,
          amount,
          apy: yieldOpportunity.apy,
          yieldAmount,
          yieldQuantity,
        });
      }
    });

    this.availableYields = availableYields;

    this.opportunityStore$.dispatch(setAvailableYieldsAction({ availableYields }));
  }

  computeCheapestPlatforms(): void {
    const cheapestPlatforms: PlatformFee[] = [
      {
        name: `BINANCE`,
        waltioAverageFees: 1.2,
      },
      {
        name: `BITSTAMP`,
        waltioAverageFees: 1.5,
      },
      {
        name: `BITGET`,
        waltioAverageFees: 2,
      },
    ];

    cheapestPlatforms.sort((a: PlatformFee, b: PlatformFee) => a.waltioAverageFees - b.waltioAverageFees);

    this.cheapestPlatforms = cheapestPlatforms;
    this.opportunityStore$.dispatch(setCheapestPlatformsAction({ cheapestPlatforms }));
  }

  computeBestAnnualFees(): void {
    if (this.userFees.volume === 0) {
      this.bestAnnualFees = 0;
    } else {
      let bestAnnualFees = 0;
      const averageFees = (this.userFees.fee / this.userFees.volume) * 100;

      bestAnnualFees = ((this.cheapestPlatforms[0].waltioAverageFees - averageFees) / averageFees) * this.userFees.fee;

      if (bestAnnualFees >= 0) {
        bestAnnualFees = 0;
      } else {
        bestAnnualFees = Math.abs(bestAnnualFees);
      }

      this.bestAnnualFees = bestAnnualFees;
    }

    this.opportunityStore$.dispatch(setBestAnnualFeesAction({ bestAnnualFees: this.bestAnnualFees }));
  }

  computeMissingOpportunity(): void {
    const missingOpportunity = this.missedPassiveYield + this.bestAnnualFees;

    this.opportunityStore$.dispatch(setMissingOpportunityAction({ missingOpportunity }));
  }

  openFeedbackDialog(): void {
    // Open feedback dialog
  }
}
