export const TRACKED_ROUTES: string[] = [
  `/accounts`,
  `/transactions`,
  `/profile`,
  `/dashboard`,
  `/opportunities`,
  `/onboarding`,
  `/tutorial`,
  `/mobile-go-desktop`,
];
