import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: `app-toggle-title`,
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./toggle-title.component.html`,
  styleUrls: [`./toggle-title.component.scss`],
})
export class ToggleTitleComponent {
  @Input() title: string;
  @Input() count: number;
  @Input() active = false;
}
