<div
  class="toggle pointer"
  [ngClass]="{
    'toggle--active': active,
  }"
>
  <p class="toggle__title">{{ title }}</p>

  @if (count !== null && count !== undefined) {
    <div class="toggle__count">
      {{ count }}
    </div>
  }
</div>
