import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReportedToken } from '../models/reported-token.model';

@Injectable({
  providedIn: `root`,
})
export class ReportedTokenService {
  constructor(private http: HttpClient) {}

  getUserReportedTokenList(): Observable<ReportedToken[]> {
    return this.http.get<ReportedToken[]>(`${environment.apiUrl}/v1/tax/user/reportedToken`);
  }

  addUserReportedToken(reportedToken: ReportedToken): Observable<ReportedToken[]> {
    let url = `${environment.apiUrl}/v1/tax/user/reportedToken?symbol=${reportedToken.symbol}`;

    if (reportedToken.address) {
      url = url.concat(`&address=${reportedToken.address}`);
    }

    if (reportedToken.platform) {
      url = url.concat(`&platform=${reportedToken.platform}`);
    }

    return this.http.post<ReportedToken[]>(url, {});
  }

  deleteUserReportedToken(reportedToken: ReportedToken): Observable<ReportedToken[]> {
    let url = `${environment.apiUrl}/v1/tax/user/reportedToken?symbol=${reportedToken.symbol}`;

    if (reportedToken.address) {
      url = url.concat(`&address=${reportedToken.address}`);
    }

    if (reportedToken.platform) {
      url = url.concat(`&platform=${reportedToken.platform}`);
    }

    return this.http.delete<ReportedToken[]>(url);
  }
}
