import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { configurationGuard } from './shared/guards/configuration.guard';
import { mobileGuard } from './shared/guards/mobile.guard';
import { OauthCallbackComponent } from './taxation/components/oauth-callback/oauth-callback.component';
import { ProfilePageComponent } from './taxation/pages/profile-page/profile-page.component';
import { partnerGuard } from './taxation/guards/partner.guard';
import { dashboardGuard } from './portfolio-manager/guards/dashboard.guard';
import { ReferralComponent } from './shared/components/referral/referral.component';
import { opportunitiesGuard } from './portfolio-manager/guards/opportunities.guard';
import { OpportunitiesPageComponent } from './portfolio-manager/pages/opportunities-page/opportunities-page.component';

export const routes: Routes = [
  {
    path: ``,
    component: LayoutComponent,
    canActivate: [AuthGuard, configurationGuard],
    children: [
      {
        path: ``,
        redirectTo: `/accounts`,
        pathMatch: `full`,
      },
      {
        path: `accounts`,
        title: `ACCOUNTS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/accounts-page/accounts-page.component`).then((m) => m.AccountsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `accounts/add`,
        title: `ACCOUNTS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/accounts-page/accounts-page.component`).then((m) => m.AccountsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `accounts/add/:account`,
        title: `ACCOUNTS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/accounts-page/accounts-page.component`).then((m) => m.AccountsPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `report/:year`,
        title: `REPORT_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/report-page/report-page.component`).then((m) => m.ReportPageComponent),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/add`,
        title: `TRANSACTIONS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/transactions-creator-page/transactions-creator-page.component`).then(
            (m) => m.TransactionsCreatorPageComponent
          ),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions`,
        title: `TRANSACTIONS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/transactions-page/transactions-page.component`).then(
            (m) => m.TransactionsPageComponent
          ),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/warnings`,
        title: `TRANSACTIONS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/transactions-page/transactions-page.component`).then(
            (m) => m.TransactionsPageComponent
          ),
        canActivate: [mobileGuard],
      },
      {
        path: `transactions/taxable`,
        title: `TRANSACTIONS_TITLE`,
        loadComponent: () =>
          import(`./taxation/pages/transactions-page/transactions-page.component`).then(
            (m) => m.TransactionsPageComponent
          ),
        canActivate: [mobileGuard],
      },
      {
        path: `profile`,
        title: `PROFILE_TITLE`,
        component: ProfilePageComponent,
        children: [
          {
            path: ``,
            loadComponent: () =>
              import(`./taxation/pages/information-page/information-page.component`).then(
                (m) => m.InformationPageComponent
              ),
          },
          {
            path: `preferences`,
            loadComponent: () =>
              import(`./taxation/pages/preferences-page/preferences-page.component`).then(
                (m) => m.PreferencesPageComponent
              ),
          },
          {
            path: `subscription`,
            loadComponent: () =>
              import(`./taxation/pages/offer-page/offer-page.component`).then((m) => m.OfferPageComponent),
          },
          {
            path: `sponsorship`,
            loadComponent: () =>
              import(`./taxation/pages/sponsorship-page/sponsorship-page.component`).then(
                (m) => m.SponsorshipPageComponent
              ),
          },
        ],
        canActivate: [mobileGuard],
      },
      {
        path: `dashboard`,
        loadComponent: () =>
          import(`./portfolio-manager/pages/dashboard-page/dashboard-page.component`).then(
            (m) => m.DashboardPageComponent
          ),
        canActivate: [dashboardGuard],
      },
      {
        path: `opportunities`,
        component: OpportunitiesPageComponent,
        canActivate: [opportunitiesGuard],
        children: [
          {
            path: ``,
            loadComponent: () =>
              import(
                `./portfolio-manager/pages/opportunities-overview-page/opportunities-overview-page.component`
              ).then((m) => m.OpportunitiesOverviewPageComponent),
          },
          {
            path: `fees`,
            loadComponent: () =>
              import(`./portfolio-manager/pages/opportunities-fees-page/opportunities-fees-page.component`).then(
                (m) => m.OpportunitiesFeesPageComponent
              ),
          },
          {
            path: `yields`,
            loadComponent: () =>
              import(`./portfolio-manager/pages/opportunities-yields-page/opportunities-yields-page.component`).then(
                (m) => m.OpportunitiesYieldsPageComponent
              ),
          },
        ],
      },
    ],
  },
  {
    path: `transactions/:id/negative-balances`,
    title: `TRANSACTIONS_TITLE`,
    loadComponent: () =>
      import(`./taxation/pages/negative-balances-page/negative-balances-page.component`).then(
        (m) => m.NegativeBalancesPageComponent
      ),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `transactions/:id/unknown-prices`,
    title: `TRANSACTIONS_TITLE`,
    loadComponent: () =>
      import(`./taxation/pages/unknown-prices-page/unknown-prices-page.component`).then(
        (m) => m.UnknownPricesPageComponent
      ),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `onboarding`,
    loadComponent: () =>
      import(`./taxation/pages/onboarding-page/onboarding-page.component`).then((m) => m.OnboardingPageComponent),
  },
  {
    path: `tutorial`,
    loadComponent: () =>
      import(`./taxation/pages/tax-guide-page/tax-guide-page.component`).then((m) => m.TaxGuidePageComponent),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `accept-transfer`,
    loadComponent: () =>
      import(`./shared/pages/email-accept-page/email-accept-page.component`).then((m) => m.EmailAcceptPageComponent),
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `2fa`,
    loadComponent: () =>
      import(`./authentication/pages/two-fa-page/two-fa-page.component`).then((m) => m.TwoFAPageComponent),
  },
  {
    path: `partner/:id`,
    loadComponent: () =>
      import(`./taxation/pages/partner-page/partner-page.component`).then((m) => m.PartnerPageComponent),
    canActivate: [partnerGuard],
  },
  {
    path: `coinbase-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `paymium-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `bitstamp-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `revolut-sync/callback`,
    component: OauthCallbackComponent,
  },
  {
    path: `finary-sync/callback`,
    component: OauthCallbackComponent,
    canActivate: [AuthGuard, configurationGuard],
  },
  {
    path: `callback`,
    loadComponent: () =>
      import(`./authentication/components/callback/callback.component`).then((m) => m.CallbackComponent),
  },
  {
    path: `thank-you`,
    loadComponent: () =>
      import(`./taxation/components/payment-callback/payment-callback.component`).then(
        (m) => m.PaymentCallbackComponent
      ),
  },
  {
    path: `mobile-go-desktop`,
    loadComponent: () =>
      import(`./shared/pages/errors/mobile-error-page/mobile-error-page.component`).then(
        (m) => m.MobileErrorPageComponent
      ),
  },
  { path: `:referralCode`, component: ReferralComponent },
  { path: `**`, redirectTo: `/accounts` },
];
