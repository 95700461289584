import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { YieldOpportunity } from '../models/yield-opportunity.model';

@Injectable({
  providedIn: `root`,
})
export class YieldService {
  constructor(private readonly http: HttpClient) {}

  getYieldOpportunities(): Observable<YieldOpportunity[]> {
    return this.http.get<YieldOpportunity[]>(`${environment.apiUrl}/v1/tax/yield/opportunities`);
  }
}
