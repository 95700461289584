import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AvailableAddons,
  AvailablePlans,
  FiscalYearPlanEstimate,
  Payment,
  PaymentEstimateV3,
  PaymentMethod,
  PendingCharge,
  PlansByFiscalYears,
} from '../models/payment.model';
import { AddOnDetails } from '../models/addon.model';
import { SubscriptionNextPayment, SubscriptionRenewalRequest } from '../models/subscription.model';
import { VaultEvent } from '../models/vault.model';
import { UserAddon, UserPlan } from '../models/user.model';
import { GenericResponse } from '../../shared/models/generic-response.model';
import { map } from 'rxjs/operators';
import { InvoiceDetails } from '../models/invoice.model';

@Injectable({
  providedIn: `root`,
})
export class PaymentService {
  constructor(private readonly http: HttpClient) {}

  // Plan
  getAvailablePlans(): Observable<AvailablePlans> {
    return this.http.get<AvailablePlans>(`${environment.apiUrl}/v1/tax/payment/v3/subscription/available-plans`);
  }

  getSubscriptionNextPayment(): Observable<SubscriptionNextPayment> {
    return this.http.get<SubscriptionNextPayment>(`${environment.apiUrl}/v1/tax/payment/v3/subscription/nextPayment`);
  }

  /**
   * Apply vault for next payment
   *
   * @returns Vault event
   */
  applyVault(): Observable<VaultEvent> {
    return this.http.post<VaultEvent>(`${environment.apiUrl}/v1/tax/payment/v3/subscription/applyVault`, ``);
  }

  renewSubscription(requestedPlan: UserPlan): Observable<SubscriptionRenewalRequest> {
    return this.http.post<SubscriptionRenewalRequest>(
      `${environment.apiUrl}/v1/tax/payment/v3/subscription/renew/stripe?requestedPlan=${requestedPlan}`,
      ``
    );
  }

  getPlanEstimate(
    fiscalYear: number,
    requestedPlan?: UserPlan,
    useVault = false,
    code = ``
  ): Observable<PaymentEstimateV3> {
    let url = `${environment.apiUrl}/v1/tax/payment/v3/subscription/upgrade?useVault=${useVault}&code=${code}&fiscalYear=${fiscalYear}`;

    if (requestedPlan) {
      url = url.concat(`&requestedPlan=${requestedPlan}`);
    }

    return this.http.get<PaymentEstimateV3>(url);
  }

  upgradePlan(
    paymentMethod: PaymentMethod,
    requestedPlan: UserPlan,
    fiscalYear: number,
    affiliateVisitorID?: string,
    tags?: string,
    useVault = false,
    code = ``
  ): Observable<Payment> {
    let url = `${environment.apiUrl}/v1/tax/payment/v3/subscription/upgrade/${paymentMethod}?requestedPlan=${requestedPlan}&useVault=${useVault}&code=${code}&fiscalYear=${fiscalYear}`;

    if (affiliateVisitorID) {
      url = url.concat(`&affiliateVisitorID=${affiliateVisitorID}`);
    }

    if (tags) {
      url = url.concat(`&tags=${tags}`);
    }

    return this.http.post<Payment>(url, ``);
  }

  // Addon
  getAvailableAddons(): Observable<AvailableAddons> {
    return this.http.get<AvailableAddons>(`${environment.apiUrl}/v1/tax/payment/v3/add-ons/available-plans`);
  }

  getAddonsDetails(): Observable<AddOnDetails[]> {
    return this.http.get<AddOnDetails[]>(`${environment.apiUrl}/v1/tax/payment/v3/add-ons/details`);
  }

  getAddonEstimate(
    fiscalYear: number,
    requestedAddOn?: UserAddon,
    useVault = false,
    code = ``
  ): Observable<PaymentEstimateV3> {
    return this.http.get<PaymentEstimateV3>(
      `${environment.apiUrl}/v1/tax/payment/v3/add-ons/enable?requestedAddOn=${requestedAddOn}&useVault=${useVault}&fiscalYear=${fiscalYear}&code=${code}`
    );
  }

  enableAddon(
    paymentMethod: PaymentMethod,
    requestedAddOn: UserAddon,
    fiscalYear: number,
    useVault = false,
    code = ``
  ): Observable<Payment> {
    return this.http.post<Payment>(
      `${environment.apiUrl}/v1/tax/payment/v3/add-ons/enable/${paymentMethod}?requestedAddOn=${requestedAddOn}&useVault=${useVault}&code=${code}&fiscalYear=${fiscalYear}`,
      ``
    );
  }

  // Charge
  getPendingCharge(): Observable<PendingCharge> {
    return this.http.get<PendingCharge>(`${environment.apiUrl}/v1/tax/payment/v3/charge/pending`);
  }

  payCharge(paymentMethod: PaymentMethod): Observable<Payment> {
    return this.http.post<Payment>(`${environment.apiUrl}/v1/tax/payment/v3/charge/pending/pay/${paymentMethod}`, ``);
  }

  // Payment
  getAllPayments(): Observable<Payment[]> {
    return this.http
      .get<Payment[]>(`${environment.apiUrl}/v1/tax/payment?includesRefunded=true`)
      .pipe(map((payments: Payment[]) => payments.filter((payment: Payment) => !payment.tags?.includes(`hidden`))));
  }

  getPlansByFiscalYears(): Observable<Map<string, FiscalYearPlanEstimate>> {
    return this.http
      .get<PlansByFiscalYears>(`${environment.apiUrl}/v1/tax/payment/v3/plans/requested`)
      .pipe(map((plansByFiscalYears: PlansByFiscalYears) => new Map(Object.entries(plansByFiscalYears.plans))));
  }

  checkVoucherCode(
    code: string,
    fiscalYear: number,
    requestedPlan?: UserPlan,
    requestedAddOn?: UserAddon
  ): Observable<GenericResponse> {
    let url = `${environment.apiUrl}/v1/tax/payment/voucher/check?code=${code}&fiscalYear=${fiscalYear}`;

    if (requestedAddOn) {
      url = url.concat(`&requestedAddon=${requestedAddOn}`);
    }

    if (requestedPlan) {
      url = url.concat(`&requestedPlan=${requestedPlan}`);
    }

    return this.http.post<GenericResponse>(url, ``);
  }

  // Invoice
  downloadInvoice(paymentId: string): Observable<string> {
    return this.http.get(`${environment.apiUrl}/v1/tax/payment/invoice/${paymentId}`, { responseType: `text` });
  }

  generateInvoice(paymentId: string, invoiceDetails: InvoiceDetails): Observable<string> {
    return this.http.post(`${environment.apiUrl}/v1/tax/payment/invoice/${paymentId}`, invoiceDetails, {
      responseType: `text`,
    });
  }
}
