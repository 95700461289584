import { Action, createReducer, on } from '@ngrx/store';
import { MarketFeed, MarketHistory, MarketPrice } from '../../models/market.model';
import * as InsightActions from '../actions/insight.action';
import { LiveBalanceResponse } from '../../models/live-balance.model';
import { YieldOpportunity } from '../../models/yield-opportunity.model';
import { UserFees } from '../../models/user-fees.model';

export interface State {
  marketFeed: MarketFeed;
  marketHistory: MarketHistory;
  liveBalances: LiveBalanceResponse;
  yieldOpportunities: YieldOpportunity[];
  userFees: UserFees;
  userFeesByPlatform: Map<string, number>;
}

export const initialState: State = {
  marketFeed: null,
  marketHistory: null,
  liveBalances: null,
  yieldOpportunities: [],
  userFees: null,
  userFeesByPlatform: null,
};

const reducer = createReducer(
  initialState,
  on(InsightActions.setMarketFeedAction, (state: State, { marketFeed }: any) => {
    const priceMap: Map<string, MarketPrice> = new Map(
      state.marketFeed?.marketFeed?.map((marketPrice: MarketPrice) => [marketPrice.symbol, marketPrice])
    );

    marketFeed.marketFeed.forEach((marketPrice: MarketPrice) => priceMap.set(marketPrice.symbol, marketPrice));

    return {
      ...state,
      marketFeed: {
        ...marketFeed,
        marketFeed: Array.from(priceMap.values()),
      },
    };
  }),
  on(InsightActions.setMarketHistoryAction, (state: State, { marketHistory }: any) => ({
    ...state,
    marketHistory,
  })),
  on(InsightActions.setLiveBalancesAction, (state: State, { liveBalances }: any) => ({
    ...state,
    liveBalances,
  })),
  on(InsightActions.setYieldOpportunitiesAction, (state: State, { yieldOpportunities }: any) => ({
    ...state,
    yieldOpportunities,
  })),
  on(InsightActions.setUserFeesAction, (state: State, { userFees }: any) => ({
    ...state,
    userFees,
  })),
  on(InsightActions.setUserFeesByPlatformAction, (state: State, { userFeesByPlatform }: any) => ({
    ...state,
    userFeesByPlatform,
  }))
);

export const insightReducer = (state: State | undefined, action: Action): State => reducer(state, action);
