<mat-toolbar>
  <nav>
    <app-toggle-title
      title="Vue d'ensemble"
      [routerLink]="'/opportunities'"
      [active]="currentPath === '/opportunities'"
    ></app-toggle-title>
    <app-toggle-title
      title="Frais et prix d'achat"
      [routerLink]="'/opportunities/fees'"
      [active]="currentPath === '/opportunities/fees'"
    ></app-toggle-title>
    <app-toggle-title
      title="Rendements passifs"
      [routerLink]="'/opportunities/yields'"
      [active]="currentPath === '/opportunities/yields'"
    ></app-toggle-title>
  </nav>

  <div class="feedback pointer" (click)="feedback.emit()">
    <img src="/assets/img/icons/chat-bubble.svg" alt="chat" />
    <p class="body-14-med">{{ 'NEW_FEEDBACK' | translate }}</p>
  </div>
</mat-toolbar>
<mat-divider></mat-divider>
