import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserFees } from '../models/user-fees.model';

@Injectable({
  providedIn: `root`,
})
export class FeeService {
  constructor(private readonly http: HttpClient) {}

  getUserFees(): Observable<UserFees> {
    return this.http.get<UserFees>(`${environment.apiUrl}/v1/tax/user-fees/stats`).pipe(
      map((userFees: UserFees) => {
        userFees.feeByPlatform = new Map<string, number>(Object.entries(userFees.feeByPlatform));
        userFees.volumeByPlatform = new Map<string, number>(Object.entries(userFees.volumeByPlatform));

        return userFees;
      })
    );
  }

  getUserFeesByPlatform(): Observable<Map<string, number>> {
    return this.http.get<Map<string, number>>(`${environment.apiUrl}/v1/tax/user-fees/stats/platform`).pipe(
      map((userFeesByPlatform: Map<string, number>) => {
        return new Map<string, number>(Object.entries(userFeesByPlatform));
      })
    );
  }
}
