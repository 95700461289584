import { Injectable } from '@angular/core';
import { ReportedToken } from '../../shared/models/reported-token.model';
import { Scam } from '../../shared/models/scam.model';
import { LiveBalanceResponseItem } from '../models/live-balance.model';
import { MarketPrice } from '../models/market.model';
import { Yield, YieldOpportunity } from '../models/yield-opportunity.model';

@Injectable({
  providedIn: `root`,
})
export class MarketService {
  isScam(balance: LiveBalanceResponseItem, userScamList: Scam[], userReportedTokenList: ReportedToken[]): boolean {
    const scam: Scam = userScamList?.find((s: Scam) => {
      if (!s.address) {
        const symbol = balance?.token?.toLowerCase();
        const scamSymbol = s.symbol?.toLowerCase();

        return symbol === scamSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const scamAddress = s.address?.toLowerCase();

        return address === scamAddress;
      }
    });

    const reportedToken = userReportedTokenList?.find((r: ReportedToken) => {
      if (!r.address) {
        const symbol = balance?.token?.toLowerCase();
        const reportedTokenSymbol = r.symbol?.toLowerCase();

        return symbol === reportedTokenSymbol;
      } else {
        const address = balance?.address?.toLowerCase();
        const reportedTokenAddress = r.address?.toLowerCase();

        return address === reportedTokenAddress;
      }
    });

    if (scam || reportedToken) {
      return true;
    } else {
      return balance?.possibleSpam || balance?.reported;
    }
  }

  getTokenPrice(marktPrices: MarketPrice[], token: string): number {
    return marktPrices.find((price: MarketPrice) => price.symbol === token)?.price || 0;
  }

  computeYieldByUpcomingYears(availableYields: Yield[], marketPrices: MarketPrice[], yearCount: number): number {
    if (yearCount === 0) {
      return 0;
    } else {
      let amount = 0;

      availableYields.forEach((availableYield: Yield) => {
        const price = this.getTokenPrice(marketPrices, availableYield.token);

        let lastYieldQuantity = availableYield.quantity;
        for (let i = 0; i < yearCount; i++) {
          const yieldQuantity = this.computeYieldQuantity(lastYieldQuantity, availableYield.apy);
          amount += yieldQuantity * price;
          lastYieldQuantity += yieldQuantity;
        }
      });

      return amount;
    }
  }

  getBestYieldOpportunity(yieldOpportunities: YieldOpportunity[], token: string): YieldOpportunity {
    const opportunities: YieldOpportunity[] = yieldOpportunities.filter(
      (opportunity: YieldOpportunity) => opportunity.token === token
    );

    if (opportunities.length === 0) {
      return null;
    } else {
      return opportunities.reduce((a: YieldOpportunity, b: YieldOpportunity) => (a?.apy > b?.apy ? a : b), null);
    }
  }

  computeYieldQuantity(quantity: number, apy: number): number {
    return quantity * (1 + apy / 100) - quantity;
  }
}
