import { Action, createReducer, on } from '@ngrx/store';
import {
  AvailableAddons,
  AvailablePlans,
  FiscalYearPlanEstimate,
  Payment,
  PaymentEstimateV3,
  PendingCharge,
} from '../../models/payment.model';
import * as PaymentActions from '../actions/payment.action';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { SubscriptionNextPayment } from '../../models/subscription.model';
import { AddOnDetails } from '../../models/addon.model';

export interface State {
  availablePlans: AvailablePlans;
  availableAddons: AvailableAddons;

  addonsDetails: AddOnDetails[];
  subscriptionNextPayment: SubscriptionNextPayment;

  paymentEstimate: PaymentEstimateV3;
  payments: Payment[];

  pendingCharge: PendingCharge;

  voucherResponse: GenericResponse;

  upgradeFishingEstimate: PaymentEstimateV3;
  plansByFiscalYears: Map<string, FiscalYearPlanEstimate>;
}

export const initialState: State = {
  availablePlans: null,

  availableAddons: null,
  addonsDetails: null,

  paymentEstimate: null,
  payments: null,

  pendingCharge: null,
  voucherResponse: null,

  upgradeFishingEstimate: null,
  plansByFiscalYears: null,
  subscriptionNextPayment: null,
};

const reducer = createReducer(
  initialState,
  on(PaymentActions.setPaymentsAction, (state: State, { payments }: any) => ({
    ...state,
    payments,
  })),
  on(PaymentActions.setAvailablePlansAction, (state: State, { availablePlans }: any) => ({
    ...state,
    availablePlans,
  })),
  on(PaymentActions.setAvailableAddonsAction, (state: State, { availableAddons }: any) => ({
    ...state,
    availableAddons,
  })),
  on(PaymentActions.setVoucherResponseAction, (state: State, { voucherResponse }: any) => ({
    ...state,
    voucherResponse,
  })),
  on(PaymentActions.setAddonsDetailsAction, (state: State, { addonsDetails }: any) => ({
    ...state,
    addonsDetails,
  })),
  on(PaymentActions.setPendingChargeAction, (state: State, { pendingCharge }: any) => ({
    ...state,
    pendingCharge,
  })),
  on(PaymentActions.loadPaymentEstimateAction, (state: State, { checkoutType, requestedPlan, requestedAddOn }: any) => {
    let newPaymentEstimate = state.paymentEstimate;

    if (checkoutType === `PLAN`) {
      if (state.paymentEstimate?.selectedPlan !== requestedPlan) {
        newPaymentEstimate = null;
      }
    } else if (state.paymentEstimate?.addon !== requestedAddOn) {
      newPaymentEstimate = null;
    }
    return {
      ...state,
      paymentEstimate: newPaymentEstimate,
    };
  }),
  on(PaymentActions.setPaymentEstimateAction, (state: State, { paymentEstimate }: any) => ({
    ...state,
    paymentEstimate,
  })),
  on(PaymentActions.setUpgradeFishingEstimateAction, (state: State, { upgradeFishingEstimate }: any) => ({
    ...state,
    upgradeFishingEstimate,
  })),
  on(PaymentActions.setPlansByFiscalYearsAction, (state: State, { plansByFiscalYears }: any) => ({
    ...state,
    plansByFiscalYears,
  })),
  on(PaymentActions.setSubscriptionNextPaymentAction, (state: State, { subscriptionNextPayment }: any) => ({
    ...state,
    subscriptionNextPayment,
  }))
);

export const paymentReducer = (state: State | undefined, action: Action): State => reducer(state, action);
