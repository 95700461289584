import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EventSourcePolyfill } from 'event-source-polyfill';

@Injectable({
  providedIn: `root`,
})
export class FeedService implements OnDestroy {
  private readonly sseEndpoint = `${environment.apiUrl}/v1/insight/feed/stream`;
  private readonly heartbeatTimeout = 120000; // 2 mins
  private eventSource: EventSource;

  startFeed(accessToken: string): EventSource {
    const options = {
      heartbeatTimeout: this.heartbeatTimeout,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        waltio_user: sessionStorage.getItem(`waltio_user`),
      },
    };
    this.eventSource = new EventSourcePolyfill(this.sseEndpoint, options);

    return this.eventSource;
  }

  stopFeed(): void {
    this.eventSource.close();
  }

  ngOnDestroy(): void {
    this.stopFeed();
  }
}
