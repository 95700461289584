import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Scam } from '../models/scam.model';

@Injectable({
  providedIn: `root`,
})
export class ScamService {
  constructor(private http: HttpClient) {}

  getUserScamList(): Observable<Scam[]> {
    return this.http.get<Scam[]>(`${environment.apiUrl}/v1/tax/user/scam`);
  }

  addUserScam(scam: Scam): Observable<Scam[]> {
    let url = `${environment.apiUrl}/v1/tax/user/scam?symbol=${scam.symbol}`;

    if (scam.address) {
      url = url.concat(`&address=${scam.address}`);
    }

    if (scam.platform) {
      url = url.concat(`&platform=${scam.platform}`);
    }

    return this.http.post<Scam[]>(url, {});
  }

  deleteUserScam(scam: Scam): Observable<Scam[]> {
    let url = `${environment.apiUrl}/v1/tax/user/scam?symbol=${scam.symbol}`;

    if (scam.address) {
      url = url.concat(`&address=${scam.address}`);
    }

    if (scam.platform) {
      url = url.concat(`&platform=${scam.platform}`);
    }

    return this.http.delete<Scam[]>(url);
  }
}
